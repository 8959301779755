import { json, LoaderFunctionArgs, redirect } from "@remix-run/node";
import { MetaFunction } from "@remix-run/react";
import { IconBrandAzure } from "@tabler/icons-react";

import { Logo } from "~/components/common/logo";
import { ErrorComponent } from "~/components/error";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import { SubmitButton } from "~/components/ui/submit-button";
import { SessionService } from "~/services/session.server";

export const meta: MetaFunction = () => {
  return [{ title: "Login | TurnsDash" }];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await SessionService.getUser(request);
  if (user) {
    return redirect("/");
  }
  return json({});
}

export default function Login() {
  return (
    <Card className="min-w-64">
      <CardHeader className="text-center">
        <CardTitle className="text-center">
          <Logo />
        </CardTitle>
      </CardHeader>
      <CardContent>
        <form action="/auth/microsoft" method="post">
          <SubmitButton className="w-full gap-x-2">
            <IconBrandAzure className="size-5" />
            <span>Login</span>
          </SubmitButton>
        </form>
      </CardContent>
    </Card>
  );
}

export function ErrorBoundary() {
  return <ErrorComponent />;
}
